import React from 'react'

import { IconProps } from '../Icon'

const Twitter: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 72 60">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill={color}
      d="M64.6289 14.9368C64.6728 15.5878 64.6728 16.2388 64.6728 16.8957C64.6728 36.9135 49.8135 60 22.6428 60V59.988C14.6165 60 6.75686 57.6422 0 53.1964C1.16709 53.3404 2.34004 53.4124 3.51591 53.4154C10.1675 53.4214 16.6289 51.1326 21.8618 46.9178C15.5408 46.7948 9.99781 42.5681 8.06143 36.3975C10.2757 36.8355 12.5572 36.7455 14.7305 36.1365C7.83912 34.7086 2.88117 28.499 2.88117 21.2874C2.88117 21.2214 2.88117 21.1585 2.88117 21.0955C4.93455 22.2684 7.23364 22.9193 9.58537 22.9913C3.0947 18.5426 1.09397 9.68718 5.01353 2.76361C12.5133 12.228 23.5788 17.9817 35.4574 18.5906C34.2669 13.3289 35.8932 7.81529 39.7309 4.11653C45.6804 -1.61911 55.0377 -1.32513 60.6303 4.77349C63.9386 4.10453 67.1093 2.85961 70.011 1.09572C68.9082 4.6025 66.6004 7.58131 63.5174 9.47419C66.4453 9.12021 69.306 8.31626 72 7.08934C70.0168 10.1371 67.5188 12.792 64.6289 14.9368Z"
    />
  </svg>
)

export default Twitter
