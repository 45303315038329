import React from 'react'

import Surface, { SurfaceProps } from 'components/Surface'

const Card: React.FC<SurfaceProps> = ({ children, ...props }) => {
  return (
    <Surface outlined {...props}>
      {children}
    </Surface>
  )
}

export default Card
