export const black = '#130c17'
export const white = '#fdf6ee'

export const grey = {
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#532E6B',
  600: '#3D1556',
  700: '#311145',
  800: '#2A0F3D',
  900: '#250E35',
}

export const green = {
  100: '#e0ffeb',
  200: '#66FF99',
  300: '#66FF99',
  400: '#66FF99',
  500: '#66FF99',
  600: '#25ad52',
  700: '#66FF99',
  800: '#66FF99',
  900: '#66FF99',
}

export const red = {
  100: '#fbd0de',
  200: '#d91c5c',
  300: '#d91c5c',
  400: '#d91c5c',
  500: '#d91c5c',
  600: '#d91c5c',
  700: '#d91c5c',
  800: '#d91c5c',
  900: '#d91c5c',
}

export const yellow = {
  100: '#f7c239',
}

const primary = {
  dark: '#00c742',
  light: '#76ef9e',
  main: '#66FF99',
  extraLight: '#b4eac6',
}

const secondary = {
  dark: '#D91C5C',
  light: '#fbd0de',
  main: '#d91c5c',
  extraLight: '#f1457f',
}

const neutral = {
  dark: '#FFFFFF',
  light: '#FFFFFF',
  main: '#FFFFFF',
}

const colors = {
  black,
  grey,
  neutral,
  primary,
  secondary,
  white,
}

export default colors
