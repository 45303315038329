import React, { useMemo } from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { lighten } from 'theme/helpers'
import useTheme from 'theme/useTheme'

export interface ButtonProps {
  children?: React.ReactNode
  color?: string
  disabled?: boolean
  full?: boolean
  href?: string
  noPadding?: boolean
  onClick?: (e: React.MouseEvent) => void
  round?: boolean
  size?: 'sm' | 'md' | 'lg'
  text?: string
  textColor?: string
  to?: string
  variant?: 'default' | 'outlined' | 'floating'
}

const Button: React.FC<ButtonProps> = ({
  children,
  color,
  disabled,
  full,
  href,
  noPadding,
  onClick,
  round = true,
  size,
  text,
  to,
  variant,
}) => {
  const { colors, fontSizes, inputSizes, mode, spacing } = useTheme()

  let buttonSize: number
  let buttonPadding: number
  let fontSize: number

  switch (size) {
    case 'sm':
      buttonPadding = spacing[3]
      buttonSize = inputSizes.sm
      fontSize = fontSizes.sm
      break
    case 'lg':
      buttonPadding = spacing[4]
      buttonSize = inputSizes.lg
      fontSize = 18
      break
    case 'md':
    default:
      buttonPadding = spacing[3]
      buttonSize = inputSizes.md
      fontSize = fontSizes.md
  }

  let background: string
  let backgroundHover: string
  let border: string
  let boxShadow = ''
  let buttonTextColor: string
  let buttonTextColorHover: string

  const { textColor, bgColor } = useMemo(() => {
    switch (mode) {
      case 'dark':
        return { textColor: colors.black, bgColor: color }
      case 'light':
        return { textColor: colors.white, bgColor: color }
    }
  }, [color, colors, mode])

  switch (variant) {
    case 'floating':
      background = 'transparent'
      backgroundHover = 'transparent'
      border = '0'
      buttonTextColor = color || colors.white
      buttonTextColorHover = lighten(bgColor || colors.black)
      break
    case 'outlined':
      const bg = color
      background = bg + '15'
      backgroundHover = bg + '35'
      border = `1px solid ${lighten(bgColor || colors.black)}11`
      buttonTextColor = color || colors.white
      buttonTextColorHover = color || colors.black
      break
    case 'default':
    default:
      background = bgColor || colors.black
      backgroundHover = lighten(bgColor || colors.black)
      border = `1px solid ${lighten(bgColor || colors.black)}55`
      boxShadow = ``
      buttonTextColor = textColor
      buttonTextColorHover = textColor
  }

  const ButtonText = useMemo(() => {
    return (
      <StyledButtonText color={buttonTextColor} disabled={disabled}>
        {text}
      </StyledButtonText>
    )
  }, [buttonTextColor, disabled, text])

  const ButtonChild = useMemo(() => {
    if (to) {
      return (
        <StyledLink to={to}>
          {ButtonText}
          {children}
        </StyledLink>
      )
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {ButtonText}
          {children}
        </StyledExternalLink>
      )
    } else {
      return (
        <>
          {ButtonText}
          {children}
        </>
      )
    }
  }, [children, ButtonText, href, to])

  return (
    <StyledButton
      background={background}
      backgroundHover={backgroundHover}
      border={border}
      boxShadow={boxShadow}
      color={buttonTextColor}
      colorHover={buttonTextColorHover}
      disabled={disabled}
      fontSize={fontSize}
      full={full}
      onClick={onClick}
      padding={!noPadding ? buttonPadding : 0}
      round={round}
      size={buttonSize}
    >
      {ButtonChild}
    </StyledButton>
  )
}

interface StyledButtonProps {
  background: string
  backgroundHover: string
  border?: string
  boxShadow?: string
  color: string
  colorHover: string
  disabled?: boolean
  fontSize: number
  full?: boolean
  padding: number
  round?: boolean
  size: number
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background: ${(props) => props.background};
  border: ${(props) => (props.border ? props.border : 0)};
  border-radius: ${(props) => (props.round ? props.theme.borderRadius : 0)}px;
  box-shadow: ${(props) => props.boxShadow};
  box-sizing: border-box;
  color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  height: ${(props) => props.size}px;
  justify-content: center;
  margin: 0;
  min-width: ${(props) => props.size}px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  outline: none;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  pointer-events: ${(props) => (!props.disabled ? undefined : 'none')};
  text-transform: capitalize;
  transition: background 0.3s, color 0.1s;
  user-select: none;
  white-space: nowrap;
  width: ${(props) => (props.full ? '100%' : undefined)};
  &:hover {
    background: ${(props) => props.backgroundHover};
    color: ${(props) => props.colorHover};
  }
`

interface StyledButtonTextProps {
  color: string
  disabled?: boolean
}
const StyledButtonText = styled.span<StyledButtonTextProps>`
  opacity: 1;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button
