import React from 'react'
import styled from 'styled-components'

import Box from 'components/Box'
import Button from 'components/Button'
import Card from 'components/Card'
import ModalActions from 'components/ModalActions'
import ModalContent from 'components/ModalContent'
import ModalHeader from 'components/ModalHeader'
import Text from 'components/Text'

import useWallet from 'contexts/wallet/useWallet'
import useTheme from 'theme/useTheme'
import Spacer from 'components/Spacer'

import { Nft } from '../../AddWallet'

interface ConnectWalletHelperTextProps {
  onAddWallet: () => Promise<void>
  onDisconnect: () => void
  nfts: Nft[]
}

const ConnectWalletHelperText: React.FC<ConnectWalletHelperTextProps> = ({
  onAddWallet,
  onDisconnect,
  nfts,
}) => {
  const { colors } = useTheme()
  const { account } = useWallet()
  return (
    <Card elevation={2}>
      <Box padding={3} width={300}>
        <ModalHeader title="Add Wallet" />
        <ModalContent leftAlign>
          <Text lineHeight={1.5}>
            Connect your wallet to add supported NFTs to your Avatar and Sticker
            Collection.
          </Text>
          <Spacer />
          <Text lineHeight={1.5} weight="bold">
            Connected Wallet:
          </Text>
          <Spacer size="xsm" />
          <TextWrapper>
            <Text faded lineHeight={1.5} monospace>
              {account}
            </Text>
          </TextWrapper>
          <Spacer size="sm" />
          <Text weight="bold">NFTs:</Text>
          <Spacer size="sm" />
          <NftsWrapper>
            {nfts.map(nft => {
              return (
                <img src={nft.imageUrl} key={nft.contractAddress + nft.tokenId} height={64} />
              )
            })}
          </NftsWrapper>
        </ModalContent>
        <ModalActions>
          <Box flex={1}>
            <Button
              color={colors.primary.main}
              full
              onClick={onDisconnect}
              variant="floating"
            >
              Back
            </Button>
          </Box>
          <Box flex={1}>
            <Button
              color={colors.primary.main}
              full
              onClick={onAddWallet}
              variant="default"
            >
              Add Wallet
            </Button>
          </Box>
        </ModalActions>
      </Box>
    </Card>
  )
}

const TextWrapper = styled.div`
  word-break: break-all;
`

const NftsWrapper = styled.div`
  display: grid;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`

export default ConnectWalletHelperText
