import React, { useEffect, useMemo, useState } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import colors from './colors'

import { hslToHex } from './helpers'
import theme from './theme'
import { Color, Mode, Mood, ThemeSurfaces } from './types'

const ThemeProvider: React.FC = ({ children }) => {
  const [mode, setMode] = useState<Mode>('dark')
  const [mood, onSetMood] = useState<Mood>('bullish')

  const {
    black,
    borderColor,
    grey,
    moodColor,
    surfaces,
    textColor,
    white,
    primary,
    secondary,
  } = useMemo(() => {
    let newBorderColor: string
    let newSurfaces: ThemeSurfaces
    let newBlack: string
    let newGrey: Color
    let newWhite: string
    let newTextColor: string
    let saturation: number
    const hue = 260

    const primary = {
      light: '#C0FF2E',
      main: '#66ff99',
      dark: '#C0FF2E',
    }
    const secondary = {
      light: '#FF6060',
      main: '#FF6060',
      dark: '#FF6060',
    }

    let moodBaseColor = mood === 'bullish' ? primary : secondary
    let newMoodColor: string

    switch (mode) {
      case 'light':
      case 'dark':
        const brightness = 5
        saturation = 30
        newBlack = hslToHex(hue, saturation, 2 + brightness)
        newWhite = colors.white
        newBorderColor = newWhite + '15'
        newMoodColor = moodBaseColor.main
        newGrey = {
          100: hslToHex(hue, saturation * 0.2, 60 + brightness),
          200: hslToHex(hue, saturation * 0.3, 45 + brightness),
          300: hslToHex(hue, saturation * 0.4, 30 + brightness),
          400: hslToHex(hue, saturation * 0.5, 25 + brightness),
          500: hslToHex(hue, saturation * 0.66, 20 + brightness),
          600: hslToHex(hue, saturation * 0.75, 15 + brightness),
          700: hslToHex(hue, saturation * 0.9, 10 + brightness),
          800: hslToHex(hue, saturation * 0.95, 7 + brightness),
          900: hslToHex(hue, saturation, 5 + brightness),
        }
        newSurfaces = {
          '-1': {
            backgroundColor: newBlack + '55',
            boxShadow: ``,
          },
          '0': {
            backgroundColor: 'transparent',
            boxShadow: `0px`,
          },
          '1': {
            backgroundColor: newGrey[800],
            boxShadow: `0px 8px 12px -4px ${newBlack}`,
          },
          '2': {
            backgroundColor: newGrey[700],
            boxShadow: `0px 4px 24px -8px ${newBlack}`,
          },
          '3': {
            backgroundColor: newGrey[600],
            boxShadow: `0px 8px 24px 0px ${newBlack}`,
          },
        }
        newTextColor = newWhite
        document.documentElement.style.backgroundColor = newGrey[900]
        //document.documentElement.style.backgroundImage = `radial-gradient(circle at top left, #121b21, #211220, #1b1221)`
        document.body.style.color = newWhite
        break
    }

    return {
      black: newBlack,
      borderColor: newBorderColor,
      grey: newGrey,
      moodColor: newMoodColor,
      surfaces: newSurfaces,
      textColor: newTextColor,
      white: newWhite,
      primary,
      secondary,
    }
  }, [mode, mood])

  useEffect(() => {
    if (mood === 'bullish') {
      //document.documentElement.style.background = `radial-gradient(circle at top right, #001514, #130c17, #0d0911)`
    }
    if (mood === 'bearish') {
      // document.documentElement.style.background = `radial-gradient(circle at top right, #170008, #130c17, #0d0911)`
    }
  }, [mood])

  return (
    <StyledThemeProvider
      theme={{
        ...theme,
        borderColor,
        colors: {
          ...theme.colors,
          black,
          grey,
          white,
          primary,
          secondary,
        },
        mode,
        mood,
        moodColor,
        onSetMode: setMode,
        onSetMood,
        surfaces,
        textColor,
      }}
    >
      {children}
    </StyledThemeProvider>
  )
}

export default ThemeProvider
