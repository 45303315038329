import React from 'react'
import styled from 'styled-components'

import useTheme from 'theme/useTheme'

export interface SurfaceProps {
  elevation?: -1 | 0 | 1 | 2 | 3
  blur?: boolean
  outlined?: boolean
  round?: boolean
  stretch?: boolean
}

const Surface: React.FC<SurfaceProps> = ({
  blur,
  children,
  elevation = 0,
  outlined,
  round,
  stretch,
}) => {
  const { borderRadius, surfaces } = useTheme()
  const { backgroundColor, boxShadow } = surfaces[elevation]
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      borderRadius={round ? borderRadius : undefined}
      boxShadow={boxShadow}
      outlined={outlined}
      stretch={stretch}
      style={{
        backdropFilter: blur ? 'blur(25px)' : undefined,
      }}
    >
      {children}
    </Wrapper>
  )
}

interface WrapperProps {
  backgroundColor: string
  borderRadius?: number
  boxShadow: string
  outlined?: boolean
  stretch?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
    props.outlined ? `1px solid ${props.theme.borderColor}` : undefined};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius + 'px' : undefined};
  box-shadow: ${(props) => props.boxShadow};
  box-sizing: border-box;
  height: ${(props) => (props.stretch ? '100%' : undefined)};
  overflow: hidden;
  position: relative;
  width: ${(props) => (props.stretch ? '100%' : undefined)};
`

export default Surface
