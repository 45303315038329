import React from 'react'

import styled from 'styled-components'

interface ModalContentProps {
  leftAlign?: boolean
}

const ModalContent: React.FC<ModalContentProps> = ({ children, leftAlign }) => {
  return <Wrapper leftAlign={leftAlign}>{children}</Wrapper>
}

const Wrapper = styled.div<ModalContentProps>`
  align-items: ${(props) => {
    return !props.leftAlign && 'center'
  }};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[3]}px;
  overflow: auto;
  text-align: ${(props) => {
    return !props.leftAlign && 'center'
  }};
  width: calc(100% - ${(props) => props.theme.spacing[3] * 2}px);
`

export default ModalContent
