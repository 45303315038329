import React from 'react'

import styled from 'styled-components'

const ModalActions: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[3]}px;
`

export default ModalActions
