import React from 'react'
import styled from 'styled-components'

import Box from 'components/Box'
import Card from 'components/Card'
import ModalContent from 'components/ModalContent'
import ModalHeader from 'components/ModalHeader'
import Text from 'components/Text'

const ConnectWalletHelperText: React.FC = () => {
  return (
    <Card elevation={2}>
      <Box padding={2}>
        <ModalHeader title="Connect Wallet" />
        <ModalContent>
          <TextWrapper>
            <Text lineHeight={1.5}>
              Please connect the wallet containing the NFTs you'd like to use on
              Slingshot.
            </Text>
          </TextWrapper>
        </ModalContent>
      </Box>
    </Card>
  )
}

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export default ConnectWalletHelperText
