import React, { useCallback, useEffect, useState } from 'react'
import { utils } from 'ethers'
import firebase from 'firebase/compat'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Box from 'components/Box'
import Spacer from 'components/Spacer'

import useWallet from 'contexts/wallet/useWallet'
import ConnectWalletModal from 'contexts/wallet/components/ConnectWalletModal'

import AddWalletHelperText from './components/AddWalletHelperText'
import ConnectWalletHelperText from './components/ConnectWalletHelperText'
import { ADD_NFT_WALLET_MESSAGE } from './constants'
import { addWalletToFirestore } from './helpers'

export const BASE_HOST =
  process.env.BACKEND_URL || 'https://staging.slingshot.finance'
export const BASE_URL = `${BASE_HOST}/api/v3`

export interface Nft {
  contractAddress: string
  imageUrl: string
  tokenId: number
  tokenUri: string
  type: 'avatar' | 'sticker'
}

const AddWallet: React.FC = () => {
  const { account, onDisconnect, provider, wcProvider } = useWallet()
  const [nfts, setNfts] = useState<Nft[]>([])

  const { pathname } = useLocation()
  const code = pathname.replace('/', '')

  const handleAddWallet = useCallback(async () => {
    if (!account || !provider) {
      return
    }

    let signature = ''
    if (wcProvider) {
      const utf8 = unescape(encodeURIComponent(ADD_NFT_WALLET_MESSAGE))
      const arr = []
      for (var i = 0; i < utf8.length; i++) {
        arr.push(utf8.charCodeAt(i))
      }
      const messageToSign = utils.hexlify(arr)
      signature = await wcProvider.connector
        .signPersonalMessage([messageToSign, account])
        .catch((err) => {
          return undefined
        })
    } else {
      signature = await provider.getSigner().signMessage(ADD_NFT_WALLET_MESSAGE)
    }

    if (!signature) {
      throw new Error('Error signing message')
    }

    addWalletToFirestore(account, code, signature)
      .then((response) => {
        window.close()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [account, code, provider, wcProvider])

  const fetchNfts = useCallback(async () => {
    if (!account) {
      return
    }
    const url = BASE_URL + '/market/nft/owner'
    const userNfts = await fetch(url, getPostRequestInit({ account }))
      .then((response) => response.json())
      .then(async (response) => {
        const nfts = (response || []) as Nft[]
        return nfts
      })
    setNfts(userNfts)
  }, [account, setNfts])

  useEffect(() => {
    fetchNfts()
  }, [fetchNfts])

  const Content = account ? (
    <AddWalletHelperText
      onDisconnect={onDisconnect}
      onAddWallet={handleAddWallet}
      nfts={nfts}
    />
  ) : (
    <Box column width={400}>
      <ConnectWalletHelperText />
      <Spacer />
      <ConnectWalletModal />
    </Box>
  )

  return (
    <Wrapper>
      {Content}
    </Wrapper>
  )
}

const getPostRequestInit = (body: any, liquidiyZoneId?: string) => {
  const requestInit = {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  } as any
  if (liquidiyZoneId) {
    requestInit.headers.liquidityZone = liquidiyZoneId
  }
  return requestInit
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
`

export default AddWallet
