import React, { useCallback, useEffect, useReducer, useState } from 'react'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink, { WalletLinkProvider } from 'walletlink'

import { ethers } from 'ethers'

import ConnectWalletModal from './components/ConnectWalletModal'
import IsRequestingSwitchNetworkModal from './components/IsRequestingSwitchNetworkModal'

import WalletContext from './WalletContext'

import walletReducer, {
  connect,
  disconnect,
  initialState,
  setAccount,
  setStatus,
} from './walletReducer'

const WalletProvider: React.FC = ({ children }) => {
  const [connectWalletIsOpen, setConnectWalletIsOpen] = useState<
    boolean | Function
  >(false)
  const [wcProvider, setWcProvider] = useState<WalletConnectProvider>()
  const [wlProvider, setWlProvider] = useState<WalletLinkProvider>()
  const [
    {
      account,
      isRequestingSwitchNetwork,
      provider,
      liquidityZoneId,
      status,
      walletType,
    },
    dispatch,
  ] = useReducer(walletReducer, initialState)

  const ethereum = (window as any).ethereum
  const hasMetamask = !!ethereum

  const handleAccountChange = useCallback(
    (accounts: string[]) => {
      dispatch(setAccount(accounts[0]))
    },
    [dispatch],
  )

  const handleConnect = useCallback(
    async (callback?: Function) => {
      setConnectWalletIsOpen(callback || true)
    },
    [setConnectWalletIsOpen],
  )

  const handleFinishConnect = useCallback(() => {
    if (typeof connectWalletIsOpen === 'function') {
      connectWalletIsOpen()
    }
    setConnectWalletIsOpen(false)
  }, [connectWalletIsOpen, setConnectWalletIsOpen])

  const handleConnectToMetaMask = useCallback(async () => {
    try {
      const accounts: string[] = await ethereum.request({
        method: 'eth_requestAccounts',
      })

      const provider = new ethers.providers.Web3Provider(ethereum, 'any')
      dispatch(connect(accounts[0], provider, 'metamask'))

      handleFinishConnect()
      return provider
    } catch (e) {
      dispatch(disconnect())
      return undefined
    }
  }, [dispatch, ethereum, handleFinishConnect])

  const handleConnectToWalletLink = useCallback(async () => {
    try {
      const walletLink = new WalletLink({
        appName: 'Slingshot.finance',
      })
      const walletLinkProvider = walletLink.makeWeb3Provider()
      Object.defineProperties(walletLinkProvider, {
        isMetaMask: { value: true },
      })

      const provider = new ethers.providers.Web3Provider(
        walletLinkProvider as any,
        'any',
      )

      let account: string | undefined
      const accounts: string[] = await walletLinkProvider.request({
        method: 'eth_requestAccounts',
      })
      account = accounts[0]
      if (!account) {
        throw new Error('No account')
      }

      dispatch(connect(account, provider, 'walletlink'))
      setWlProvider(walletLinkProvider)

      handleFinishConnect()
      return provider
    } catch (e) {
      dispatch(disconnect())
      return undefined
    }
  }, [handleFinishConnect])

  const handleDisconnect = useCallback(() => {
    if (wcProvider) {
      wcProvider.disconnect()
    } else if (wlProvider) {
      wlProvider.disconnect()
    }
    dispatch(disconnect())
  }, [dispatch, wcProvider, wlProvider])

  const handleConnectToWalletConnect = useCallback(async () => {
    try {
      const walletConnectProvider = new WalletConnectProvider({
        rpc: {
          1: '',
        },
        qrcodeModalOptions: {
          mobileLinks: ['metamask', 'rainbow'],
        },
      })
      await walletConnectProvider.enable()
      const provider = new ethers.providers.Web3Provider(walletConnectProvider)
      dispatch(
        connect(
          walletConnectProvider.accounts[0].toLowerCase(),
          provider,
          'walletconnect',
        ),
      )
      setWcProvider(walletConnectProvider)

      handleFinishConnect()
      return provider
    } catch (e) {
      dispatch(disconnect())
      return undefined
    }
  }, [dispatch, handleFinishConnect])

  useEffect(() => {
    if (!ethereum) {
      return
    }
    ethereum.on('accountsChanged', handleAccountChange)
  }, [ethereum, handleAccountChange])

  useEffect(() => {
    if (!wlProvider) {
      return
    }
    wlProvider.on('accountsChanged', handleAccountChange)
  }, [wlProvider, handleAccountChange])

  return (
    <WalletContext.Provider
      value={{
        account,
        hasMetamask,
        liquidityZoneId,
        onConnect: handleConnect,
        onConnectToMetaMask: handleConnectToMetaMask,
        onConnectToWalletConnect: handleConnectToWalletConnect,
        onConnectToWalletLink: handleConnectToWalletLink,
        onDisconnect: handleDisconnect,
        provider,
        status,
        walletType,
        wcProvider,
      }}
    >
      {children}
      {isRequestingSwitchNetwork && walletType === 'metamask' && (
        <IsRequestingSwitchNetworkModal onDismiss={handleFinishConnect} />
      )}
      {connectWalletIsOpen && (
        <ConnectWalletModal onDismiss={handleFinishConnect} />
      )}
    </WalletContext.Provider>
  )
}

export default WalletProvider
