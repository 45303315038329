import React, { useCallback, useEffect, useState } from 'react'

import { animated, useSpring } from 'react-spring'
import styled, { keyframes } from 'styled-components'

import Portal from 'components/Portal'

export interface ModalProps {
  isOpen?: boolean
  onDismiss?: () => void
  onFinishedPresenting?: () => void
  size?: 'lg' | 'md' | 'sm'
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen = true,
  onDismiss,
  onFinishedPresenting,
  size = 'md',
}) => {
  const [visible, setVisible] = useState(false)

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onDismiss) {
        onDismiss()
      }
    },
    [onDismiss],
  )

  const handleDismiss = useCallback(
    (e: React.MouseEvent) => {
      if (onDismiss) {
        onDismiss()
        e.stopPropagation()
      }
    },
    [onDismiss],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  useEffect(() => {
    if (isOpen) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [isOpen, setVisible])

  const translateY = visible ? 0 : 128

  const contentProps = useSpring({
    transform: `translateY(${translateY}px)`,
    config: {
      tension: 500,
    },
    onRest: onFinishedPresenting,
  })

  let maxWidth: number
  switch (size) {
    case 'sm':
      maxWidth = 350
      break
    case 'lg':
      maxWidth = 900
      break
    case 'md':
    default:
      maxWidth = 600
  }

  if (isOpen) {
    return (
      <Portal>
        <Wrapper>
          <Backdrop onClick={handleDismiss} />
          <Content
            style={{
              maxWidth,
              transform: contentProps.transform,
            }}
          >
            {children}
          </Content>
        </Wrapper>
      </Portal>
    )
  }

  return null
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  align-items: center;
  animation: ${fadeIn} 0.3s forwards;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`

const Backdrop = styled.div`
  background: ${(props) => props.theme.colors.black};
  opacity: 0.75;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Content = animated(styled.div`
  background-color: ${(props) => props.theme.surfaces[2].backgroundColor};
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: ${(props) => props.theme.surfaces[3].boxShadow};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-height: 80vh;
  overflow: hidden;
  position: relative;
  width: 100%;
`)

export default Modal
