import React from 'react'

import Loader from 'components/Loader'
import Modal, { ModalProps } from 'components/Modal'
import ModalHeader from 'components/ModalHeader'
import ModalContent from 'components/ModalContent'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

const IsRequestingSwitchNetworkModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal size="sm" {...props}>
      <ModalHeader title="Network Switch" />
      <ModalContent>
        <Loader size={64} />
        <Spacer />
        <Text faded>Confirm network switch in your wallet</Text>
      </ModalContent>
    </Modal>
  )
}

export default IsRequestingSwitchNetworkModal
