import React, { useMemo } from 'react'
import styled from 'styled-components'

import useTheme from 'theme/useTheme'

import AccountCircle from './icons/AccountCircle'
import Add from './icons/Add'
import ArrowNorthEast from './icons/ArrowNorthEast'
import ArrowRight from './icons/ArrowRight'
import ArrowSouthEast from './icons/ArrowSouthEast'
import Bell from './icons/Bell'
import Block from './icons/Block'
import BlurOn from './icons/BlurOn'
import Cancel from './icons/Cancel'
import Candlesticks from './icons/Candlesticks'
import CaretDown from './icons/CaretDown'
import CaretUp from './icons/CaretUp'
import CheckCircle from './icons/CheckCircle'
import Check from './icons/Check'
import ChevronDown from './icons/ChevronDown'
import ChevronLeft from './icons/ChevronLeft'
import ChevronRight from './icons/ChevronRight'
import Clock from './icons/Clock'
import Close from './icons/Close'
import CopyContent from './icons/CopyContent'
import Depth from './icons/Depth'
import DollarSign from './icons/DollarSign'
import DoubleArrow from './icons/DoubleArrow'
import DoubleArrowDown from './icons/DoubleArrowDown'
import DoubleArrowUp from './icons/DoubleArrowUp'
import DownloadFile from './icons/DownloadFile'
import Edit from './icons/Edit'
import EmojiEmotions from './icons/EmojiEmotions'
import Error from './icons/Error'
import ExpandLess from './icons/ExpandLess'
import Feedback from './icons/Feedback'
import Fingerprint from './icons/Fingerprint'
import Filter from './icons/Filter'
import FilterOutlined from './icons/FilterOutlined'
import Fullscreen from './icons/Fullscreen'
import FullscreenExit from './icons/FullscreenExit'
import GasPump from './icons/GasPump'
import Github from './icons/Github'
import Globe from './icons/Globe'
import Help from './icons/Help'
import History from './icons/History'
import InsertDriveFile from './icons/InsertDriveFile'
import LightBulb from './icons/LightBulb'
import LightBulbOutlined from './icons/LightBulbOutlined'
import Lock from './icons/Lock'
import Menu from './icons/Menu'
import More from './icons/More'
import Mug from './icons/Mug'
import OpenInNew from './icons/OpenInNew'
import Pause from './icons/Pause'
import Play from './icons/Play'
import Redeem from './icons/Redeem'
import Remove from './icons/Remove'
import Sparklines from './icons/Sparklines'
import Save from './icons/Save'
import Search from './icons/Search'
import Send from './icons/Send'
import Star from './icons/Star'
import StarBorder from './icons/StarBorder'
import Swap from './icons/Swap'
import SyncAlt from './icons/SyncAlt'
import Tag from './icons/Tag'
import ThreeSixty from './icons/ThreeSixty'
import Trophy from './icons/Trophy'
import Twitter from './icons/Twitter'
import UnfoldMore from './icons/UnfoldMore'
import Warning from './icons/Warning'

export type Icons =
  | 'account-circle'
  | 'add'
  | 'arrow-north-east'
  | 'arrow-right'
  | 'arrow-south-east'
  | 'bell'
  | 'block'
  | 'blur-on'
  | 'cancel'
  | 'candlesticks'
  | 'caret-down'
  | 'caret-up'
  | 'check'
  | 'check-circle'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'clock'
  | 'close'
  | 'copy-content'
  | 'depth'
  | 'dollar-sign'
  | 'double-arrow'
  | 'double-arrow-down'
  | 'double-arrow-up'
  | 'download-file'
  | 'edit'
  | 'emoji-emotions'
  | 'error'
  | 'expand-less'
  | 'feedback'
  | 'fingerprint'
  | 'filter'
  | 'filter-outlined'
  | 'fullscreen'
  | 'fullscreen-exit'
  | 'gas-pump'
  | 'github'
  | 'globe'
  | 'help'
  | 'history'
  | 'insert-drive-file'
  | 'light-bulb'
  | 'light-bulb-outlined'
  | 'lock'
  | 'menu'
  | 'more'
  | 'mug'
  | 'open-in-new'
  | 'pause'
  | 'play'
  | 'redeem'
  | 'remove'
  | 'save'
  | 'search'
  | 'send'
  | 'sparklines'
  | 'star'
  | 'star-border'
  | 'swap'
  | 'sync-alt'
  | 'tag'
  | 'three-sixty'
  | 'trophy'
  | 'twitter'
  | 'unfold-more'
  | 'warning'

export interface IconProps {
  color?: string
  faded?: boolean
  name?: Icons
  size?: number
}

const Icon: React.FC<IconProps> = ({ color, faded, name, size = 18 }) => {
  const { textColor } = useTheme()

  const Child = useMemo(() => {
    const iconColor = color || textColor
    switch (name) {
      case 'account-circle':
        return <AccountCircle color={iconColor} size={size} />
      case 'add':
        return <Add color={iconColor} size={size} />
      case 'arrow-right':
        return <ArrowRight color={iconColor} size={size} />
      case 'arrow-north-east':
        return <ArrowNorthEast color={iconColor} size={size} />
      case 'arrow-south-east':
        return <ArrowSouthEast color={iconColor} size={size} />
      case 'bell':
        return <Bell color={iconColor} size={size} />
      case 'block':
        return <Block color={iconColor} size={size} />
      case 'blur-on':
        return <BlurOn color={iconColor} size={size} />
      case 'cancel':
        return <Cancel color={iconColor} size={size} />
      case 'candlesticks':
        return <Candlesticks color={iconColor} size={size} />
      case 'caret-down':
        return <CaretDown color={iconColor} size={size} />
      case 'caret-up':
        return <CaretUp color={iconColor} size={size} />
      case 'check-circle':
        return <CheckCircle color={iconColor} size={size} />
      case 'check':
        return <Check color={iconColor} size={size} />
      case 'chevron-down':
        return <ChevronDown color={iconColor} size={size} />
      case 'chevron-left':
        return <ChevronLeft color={iconColor} size={size} />
      case 'chevron-right':
        return <ChevronRight color={iconColor} size={size} />
      case 'clock':
        return <Clock color={iconColor} size={size} />
      case 'close':
        return <Close color={iconColor} size={size} />
      case 'copy-content':
        return <CopyContent color={iconColor} size={size} />
      case 'depth':
        return <Depth color={iconColor} size={size} />
      case 'dollar-sign':
        return <DollarSign color={iconColor} size={size} />
      case 'double-arrow':
        return <DoubleArrow color={iconColor} size={size} />
      case 'double-arrow-down':
        return <DoubleArrowDown color={iconColor} size={size} />
      case 'double-arrow-up':
        return <DoubleArrowUp color={iconColor} size={size} />
      case 'download-file':
        return <DownloadFile color={iconColor} size={size} />
      case 'edit':
        return <Edit color={iconColor} size={size} />
      case 'emoji-emotions':
        return <EmojiEmotions color={iconColor} size={size} />
      case 'error':
        return <Error color={iconColor} size={size} />
      case 'expand-less':
        return <ExpandLess color={iconColor} size={size} />
      case 'feedback':
        return <Feedback color={iconColor} size={size} />
      case 'fingerprint':
        return <Fingerprint color={iconColor} size={size} />
      case 'filter':
        return <Filter color={iconColor} size={size} />
      case 'filter-outlined':
        return <FilterOutlined color={iconColor} size={size} />
      case 'fullscreen':
        return <Fullscreen color={iconColor} size={size} />
      case 'fullscreen-exit':
        return <FullscreenExit color={iconColor} size={size} />
      case 'gas-pump':
        return <GasPump color={iconColor} size={size} />
      case 'github':
        return <Github color={iconColor} size={size} />
      case 'globe':
        return <Globe color={iconColor} size={size} />
      case 'help':
        return <Help color={iconColor} size={size} />
      case 'history':
        return <History color={iconColor} size={size} />
      case 'insert-drive-file':
        return <InsertDriveFile color={iconColor} size={size} />
      case 'light-bulb':
        return <LightBulb color={iconColor} size={size} />
      case 'light-bulb-outlined':
        return <LightBulbOutlined color={iconColor} size={size} />
      case 'lock':
        return <Lock color={iconColor} size={size} />
      case 'menu':
        return <Menu color={iconColor} size={size} />
      case 'more':
        return <More color={iconColor} size={size} />
      case 'mug':
        return <Mug color={iconColor} size={size} />
      case 'open-in-new':
        return <OpenInNew color={iconColor} size={size} />
      case 'pause':
        return <Pause color={iconColor} size={size} />
      case 'play':
        return <Play color={iconColor} size={size} />
      case 'redeem':
        return <Redeem color={iconColor} size={size} />
      case 'remove':
        return <Remove color={iconColor} size={size} />
      case 'save':
        return <Save color={iconColor} size={size} />
      case 'search':
        return <Search color={iconColor} size={size} />
      case 'send':
        return <Send color={iconColor} size={size} />
      case 'sparklines':
        return <Sparklines color={iconColor} size={size} />
      case 'star':
        return <Star color={iconColor} size={size} />
      case 'star-border':
        return <StarBorder color={iconColor} size={size} />
      case 'swap':
        return <Swap color={iconColor} size={size} />
      case 'sync-alt':
        return <SyncAlt color={iconColor} size={size} />
      case 'tag':
        return <Tag color={iconColor} size={size} />
      case 'three-sixty':
        return <ThreeSixty color={iconColor} size={size} />
      case 'trophy':
        return <Trophy color={iconColor} size={size} />
      case 'twitter':
        return <Twitter color={iconColor} size={size} />
      case 'unfold-more':
        return <UnfoldMore color={iconColor} size={size} />
      case 'warning':
        return <Warning color={iconColor} size={size} />
      default:
        return null
    }
  }, [color, name, size, textColor])

  let opacity = 1
  if (faded) {
    opacity = 0.5
  }

  return (
    <StyledIcon size={size} style={{ opacity: opacity }}>
      {Child}
    </StyledIcon>
  )
}

interface StyledIconProps {
  size: number
}

const StyledIcon = styled.div<StyledIconProps>`
  display: inline-flex;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`

export default Icon
