import firebase from 'firebase/compat/app'

export const addWalletToFirestore = async (
  account: string,
  code: string,
  signature: string,
) => {
  const addNftWallet = firebase.functions().httpsCallable('users-addNftWallet')
  return await addNftWallet({ account, code, signature })
}
