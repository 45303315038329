import React from "react";

import WalletProvider from "contexts/wallet/WalletProvider";

import { ThemeProvider } from "theme";

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider>
      <WalletProvider>{children}</WalletProvider>
    </ThemeProvider>
  );
};

export default Providers;
