import React, { useMemo } from 'react'
import styled from 'styled-components'

import useTheme from 'theme/useTheme'

type SeparatorOrientation = 'horizontal' | 'vertical'

interface SeparatorProps {
  orientation?: SeparatorOrientation
  stretch?: boolean
}

const Separator: React.FC<SeparatorProps> = ({ orientation, stretch }) => {
  const { borderColor } = useTheme()

  const background = borderColor

  const Content = useMemo(() => {
    return <StyledSeparator background={background} orientation={orientation} />
  }, [background, orientation])

  if (stretch) {
    return <div style={{ alignSelf: 'stretch' }}>{Content}</div>
  }

  return Content
}

interface StyledSeparatorProps {
  background: string
  orientation?: SeparatorOrientation
}

const StyledSeparator = styled.div<StyledSeparatorProps>`
  background: ${(props) => props.background};
  min-height: ${(props) => (props.orientation === 'vertical' ? '100%' : '1px')};
  max-width: ${(props) =>
    props.orientation === 'vertical' ? '1px' : undefined};
  min-width: ${(props) => (props.orientation === 'vertical' ? '1px' : '100%')};
  user-select: none;
`

export default Separator
