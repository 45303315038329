import React from 'react'
import styled from 'styled-components'

import { ReactComponent as MetaMaskLogo } from 'assets/svg/metamask-logo.svg'
import { ReactComponent as WalletConnectLogo } from 'assets/svg/wallet-connect-logo.svg'
import { ReactComponent as CoinbaseWalletLogo } from 'assets/svg/coinbase-wallet-logo.svg'

import Box from 'components/Box'
import Button from 'components/Button'
import Card from 'components/Card'
import Icon from 'components/Icon'
import Modal, { ModalProps } from 'components/Modal'
import ModalActions from 'components/ModalActions'
import ModalContent from 'components/ModalContent'
import ModalHeader from 'components/ModalHeader'
import Separator from 'components/Separator'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import useWallet from 'contexts/wallet/useWallet'

const ConnectWalletModal: React.FC<ModalProps> = ({ ...modalProps }) => {
  const {
    hasMetamask,
    onConnectToMetaMask,
    onConnectToWalletConnect,
    onConnectToWalletLink,
  } = useWallet()

  return (
    <Card elevation={2}>
      <Box padding={3}>
        <ModalContent>
          <WalletsWrapper>
            {hasMetamask && (
              <>
                <WalletWrapper onClick={onConnectToMetaMask}>
                  <Box row>
                    <MetaMaskLogo height={44} />
                    <Spacer size="sm" />
                    <Text weight="bold">MetaMask</Text>
                  </Box>
                  <Icon name="chevron-right" />
                </WalletWrapper>
                <Separator />
              </>
            )}
            <WalletWrapper
              onClick={() => {
                console.log('clicked')
                onConnectToWalletConnect()
              }}
            >
              <Box row>
                <WalletConnectLogo height={32} />
                <Spacer size="sm" />
                <Text weight="bold">WalletConnect</Text>
              </Box>
              <Icon name="chevron-right" />
            </WalletWrapper>
            <Separator />
            <WalletWrapper onClick={onConnectToWalletLink}>
              <Box row>
                <CoinbaseWalletLogo height={32} />
                <Spacer size="sm" />
                <Text weight="bold">Coinbase Wallet</Text>
              </Box>
              <Icon name="chevron-right" />
            </WalletWrapper>
          </WalletsWrapper>
        </ModalContent>
        <ModalActions>
          <Button
            href="https://metamask.io"
            onClick={modalProps.onDismiss}
            text="Don't have a wallet?"
            variant="floating"
          />
        </ModalActions>
      </Box>
    </Card>
  )
}

const WalletsWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.borderColor};
  width: 100%;
`

const WalletWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing[3]}px 0;
  &:hover {
    opacity: 0.5;
  }
`

export default ConnectWalletModal
