import { createContext } from 'react'

import { WalletContextValues } from './types'

const WalletContext = createContext<WalletContextValues>({
  onConnect: () => new Promise(() => {}).then(() => undefined),
  onConnectToMetaMask: () => new Promise(() => {}).then(() => undefined),
  onConnectToWalletConnect: () => new Promise(() => {}).then(() => undefined),
  onConnectToWalletLink: () => new Promise(() => {}).then(() => undefined),
  onDisconnect: () => {},
})

export default WalletContext
