import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Separator from 'components/Separator'

import AddWalletView from 'views/AddWalletView'

import Providers from './Providers'

const App: React.FC = () => {
  return (
    <Router>
      <Providers>
        <Separator />
        <AddWalletView />
      </Providers>
    </Router>
  )
}

export default App
