import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const Portal: React.FC = ({ children }) => {
  const portalRoot = document.getElementById('portal-root')
  const elRef = useRef(document.createElement('div'))

  useEffect(() => {
    const cur = elRef.current
    portalRoot?.appendChild(cur)
    return () => {
      portalRoot?.removeChild(cur)
    }
  }, [elRef, portalRoot])

  return createPortal(<Wrapper>{children}</Wrapper>, elRef.current)
}

const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

export default Portal
