import React from 'react'

import styled from 'styled-components'

import Text from 'components/Text'

import useTheme from 'theme/useTheme'

interface ModalHeaderProps {
  banner?: boolean
  bannerImgUrl?: string
  error?: boolean
  title?: string
}
const ModalHeader: React.FC<ModalHeaderProps> = ({
  banner,
  bannerImgUrl,
  children,
  error,
  title,
}) => {
  const { colors } = useTheme()
  const titleColor = error ? colors.secondary.main : colors.white
  const Title = !!title ? (
    <Text
      black={banner}
      color={titleColor}
      sizePx={banner ? 32 : 20}
      weight="bold"
    >
      {title}
    </Text>
  ) : undefined

  return (
    <Wrapper
      style={{
        backgroundImage: banner ? `url(${bannerImgUrl})` : undefined,
        height: banner ? 212 : undefined,
      }}
    >
      {!banner ? Title : <BannerOverlay>{Title}</BannerOverlay>}
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  background-position: center center;
  background-size: cover;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[3]}px;
  position: relative;
`

const BannerOverlay = styled.div`
  align-items: center;
  background: linear-gradient(#ffffff11, #00000088);
  bottom: -1px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-shadow: 0px 0px 4px black;
  top: 0;
  z-index: 0;
`

export default ModalHeader
